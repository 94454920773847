<template>
  <div class="aplayz_modal">
    <div class="aplayz_modal_confirm_wrap">
      <h3 class="modal_contents" v-html="contents"></h3>

      <ul class="confirm_btn_group dp_flex">
        <li class="confirm_btngroup_wrap cursor" @click="$emit('cancel-click')">
          <button class="confirm_btn">아니오</button>
        </li>
        <li class="confirm_btngroup_wrap cursor" @click="$emit('confirm-click')">
          <button class="confirm_btn confirm">예</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ConfirmYesNo',
  props: {
    contents: {
      type: String,
      default: '해당 큐레이션을 삭제하시겠습니까?',
      required: false
    }
  },
  emits: ['cancel-click', 'confirm-click'],
  data() {
    return {};
  },

  mounted() {},

  methods: {}
});
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/common/comfirmyesno.css"></style>
