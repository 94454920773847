<template>
  <div class="alert" :class="this.$store.getters['simplealert/getIsShow'] ? 'alert_show' : 'alert_hide'">
    <div class="single-file-box alert_box">
      <h3 v-html="this.$store.getters['simplealert/getBodyText']"></h3>
      <div v-if="this.$store.getters['simplealert/getIsYn']" class="single-file-check">
        <span @click="close()">아니오</span>
        <span class="red_text" @click="yesClose()">네</span>
      </div>
      <div v-else class="single-file-check">
        <span class="red_text" @click="close()">확인</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleAlert',
  props: {
    alertText: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
    // console.log(this.$store.getters['simplealert/getIsShow']);
  },

  methods: {
    close() {
      this.$store.commit('simplealert/close');
    },
    yesClose() {
      this.$store.commit('simplealert/setIsFunc');
      setTimeout(() => {
        this.$store.commit('simplealert/close');
      }, 300);
    }
  }
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check span {
    font-size: 15px !important;
    color: #ff2d55;
    font-weight: 400;
  }
}
.single-file-check {
  display: table;
  width: 100%;
}
.single-file-check span {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  align-items: center;
  color: var(--FFFFFFDE);
  font-size: 16px;
  cursor: pointer;
}
.single-file-check .red_text {
  color: var(--FFFFFFDE);
  color: #ff2d55;
}
.alert_show {
  display: block !important;
}
.alert_hide {
  display: none !important;
}
</style>

<!--font-size: 16px;
line-height: 24px;
color: var(--FFFFFFDE);
font-weight: 400;
padding: 16px 0;-->
