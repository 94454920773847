<template>
  <div class="text_wrap">
    <div class="text_box">
      <div class="text_header">
        <img class="less_than" src="/media/img/vector.png" @click="onBackClose" alt="lessthan" />
        <p>텍스트 입력</p>
        <img src="/media/img/close_btn.png" @click="onClickClose" alt="닫기" class="cursor" />
      </div>
      <p class="upload_text">
        {{ !isFilename ? '음성 파일로 변환할 텍스트를 입력해주세요.' : '파일명을 설정해주세요.' }}
      </p>

      <!--box 1-->
      <div v-show="!isFilename" class="box-1">
        <div class="textarea_wrap">
          <textarea
            class="text_upload"
            v-model="textContents"
            placeholder="내용을 입력해주세요."
            @input="inputContents"
          ></textarea>
          <span class="text_count">
            <span class="text_num">{{ textContents.length }}</span>
            / 300
          </span>
        </div>

        <div class="voice_title">선호 목소리</div>
        <div class="gender_button">
          <button type="button" class="women_box" @click="checkGender" :class="{ checked_male: !isMale }" id="0">
            <span>여자</span>
          </button>
          <button type="button" class="man_box" @click="checkGender" :class="{ checked_male: isMale }" id="1">
            <span>남자</span>
          </button>
        </div>

        <button type="button" class="play_button" :class="{ active_button: textContents !== '' }" @click="prePlay">
          <span class="play_text" :class="{ stop: isPlay }">{{ isPlay ? '정지 하기' : '미리 듣기' }}</span>
        </button>
      </div>
      <!--box 2-->

      <div class="name_wrap" v-show="fileInfo !== '' && isFilename">
        <input
          class="file_name"
          :class="{ border_red: filename.length > 10 }"
          type="text"
          :value="filename"
          @input="inputName"
          placeholder="ex) 오프닝용 멘트"
          ref="name_input"
        />
        <img src="/media/img/close_btn.png" @click="removeName()" alt="삭제" />
        <p v-show="isWarn" class="text_warn">파일명은 띄어쓰기 포함 10글자까지만 가능합니다.</p>
      </div>

      <button
        type="button"
        class="next_button"
        @click="nextButton()"
        :class="{ opacity_30: (textContents === '' && !isFilename) || (filename === '' && isFilename) }"
      >
        {{ isFilename ? '완료' : '다음' }}
      </button>
    </div>
    <SimpleAlert />
    <LodingCircle v-show="isLoding" />
    <audio ref="play_ref"></audio>
  </div>
</template>

<script>
import { listenTextPreviously, downloadTTSFile, registMySoundByText } from '@/service/api/mysound';

import SimpleAlert from '@/components/modal/Space/SimpleAlert.vue';
import LodingCircle from '@/components/common/LodingCircle.vue';
import VueCookies from 'vue-cookies';
export default {
  name: 'FileUploadModal',
  emits: ['close-modal'],
  components: {
    SimpleAlert,
    LodingCircle
  },
  props: {
    alertText: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      textContents: '',
      isFilename: false,
      isMale: false,
      fileInfo: '',
      filename: '',
      countNum: 0,
      isCallApi: true,
      isPlay: false,
      isClick: false,
      isLoding: false,
      isWarn: false
    };
  },
  computed: {
    isFinal() {
      return this.$store.getters['simplealert/getIsFinal'];
    },
    isFunc() {
      return this.$store.getters['simplealert/getIsFunc'];
    }
  },
  watch: {
    isFunc(newData, oldData) {
      if (newData) {
        this.$VideoPlayer.onPause();
        this.$store.commit('setPlay', false);
        this.playSet();
      }
    },
    isFinal(newData, oldData) {
      if (oldData) this.onClickClose();
    },
    isClick() {
      setTimeout(() => {
        this.isClick = false;
      }, 1000);
    },
    isMale() {
      this.isCallApi = true;
      this.isPlay = false;
      this.$refs.play_ref.src = '';
    },
    textContents() {
      this.isCallApi = true;
      this.$refs.play_ref.src = '';
    }
  },
  mounted() {},
  methods: {
    onBackClose() {
      if (this.isFilename) {
        this.isFilename = false;
      } else {
        this.$emit('close-modal');
      }
      this.resetInfo();
    },
    onClickClose() {
      this.resetInfo();
      this.$emit('close-modal');
    },
    resetInfo() {
      this.isFilename = false;
      this.filename = '';
      this.fileInfo = '';
      this.textContents = '';
      this.isCallApi = true;
      this.$refs.play_ref.src = '';
      this.isLoding = false;
    },
    // Start API

    async listenTextPreviouslyFnc() {
      this.isLoding = true;
      const param = {
        userId: VueCookies.get('userId'),
        spaceId: VueCookies.get('spaceId'),
        textOfMessage: this.textContents,
        voiceType: this.isMale ? 'male' : 'female',
        voiceName: this.isMale ? 'male' : 'female'
      };
      try {
        const returnVal = await listenTextPreviously(param);
        if (returnVal.data.resultCd === '0000') {
          this.fileInfo = returnVal.data.result;

          return returnVal;
        }
      } catch (e) {
        console.error(e);
        // this.$store.commit('simplealert/open', e.response.data.resultMsg);
      } finally {
        this.isLoding = false;
      }
    },
    async downloadTTSFileFnc() {
      this.isLoding = true;

      const param = {
        userId: VueCookies.get('userId'),
        spaceId: VueCookies.get('spaceId'),
        tempNo: this.fileInfo.tempNo
      };
      try {
        const returnVal = await downloadTTSFile(param);

        if (returnVal.data.resultCd === '0000') {
          const videoSrc = 'data:audio/mpeg;base64,' + returnVal.data.result.body;
          // const base64Response = await fetch(videoSrc);
          // const blob = await base64Response.blob();
          // const getUrl = URL.createObjectURL(blob);
          this.$refs.play_ref.src = videoSrc;
          this.isCallApi = false;
        }
      } catch (e) {
        console.error(e);
        // this.$store.commit('simplealert/open', e.response.data.resultMsg);
      } finally {
        this.isLoding = false;
      }
    },

    async registMySoundByTextFnc() {
      this.isLoding = true;
      const param = {
        userId: VueCookies.get('userId'),
        spaceId: VueCookies.get('spaceId'),
        soundTitle: this.filename,
        tempNo: this.fileInfo.tempNo
      };
      try {
        const returnVal = await registMySoundByText(param);
        if (returnVal.data.resultCd === '0000') {
          this.resetInfo();
          this.$store.commit('simplealert/openFinal', '파일이 등록되었습니다.');
        }
      } catch (e) {
        console.error(e.response.data);
        this.$store.commit('simplealert/open', e.response.data.resultMsg);
      } finally {
        this.isLoding = false;
      }
    },

    // End API

    inputContents() {
      if (this.textContents.length > 300) {
        this.$store.commit('simplealert/open', '300글자 까지 가능합니다.');
        this.textContents = this.textContents.slice(0, 300);
      }
    },
    checkGender(event) {
      const getId = event.currentTarget.id;
      this.isMale = getId * 1;
    },

    async prePlay() {
      if (this.isClick) return;
      this.isClick = true;

      if (this.textContents === '') {
        return this.$store.commit('simplealert/open', '텍스트를 입력해주세요.');
      }

      if (this.$store.state.isPlay) {
        return this.$store.commit(
          'simplealert/ynOpen',
          '현재 재생중인 음악을 잠시 멈추고 <br/> 선택하신 음악을 재생하시겠습니까?'
        );
      }

      await this.playSet();
    },
    async playSet() {
      if (this.isCallApi) {
        const getData = await this.listenTextPreviouslyFnc();
        await this.downloadTTSFileFnc(getData.data.result.fileOnServer);
      }
      if (this.$refs.play_ref.paused) {
        this.isPlay = true;
        this.$refs.play_ref.play();
      } else {
        this.isPlay = false;
        this.$refs.play_ref.pause();
        this.$refs.play_ref.currentTime = 0;
      }

      this.$refs.play_ref.addEventListener('ended', () => {
        this.isPlay = false;
      });
    },

    inputName(e) {
      const text = e.target.value;

      if (text.length > 10) {
        this.isWarn = true;
        this.filename = text.slice(0, 10);
        setTimeout(() => {
          this.isWarn = false;
        }, 1000);
      } else {
        this.isWarn = false;
        this.filename = text;
      }
    },

    removeName() {
      this.filename = '';
    },
    async nextButton() {
      if (this.isClick) return;
      this.isClick = true;
      if (this.textContents === '') return;
      if (this.filename === '' && !this.isFilename) {
        if (this.fileInfo === '') {
          await this.listenTextPreviouslyFnc();
          this.isFilename = true;
        } else {
          this.isFilename = true;
        }
      } else {
        // step2
        if (this.filename === '') {
          this.$refs.name_input.focus();

          this.$store.commit('simplealert/open', '파일 이름을 입력하세요.');
        } else if (this.filename.length > 11) {
          return this.$refs.name_input.focus();
        } else {
          this.handleDebounce(this.registMySoundByTextFnc());
        }
      }
    },

    handleDebounce(callback, ms = 300) {
      let fnc;
      return function(...args) {
        clearTimeout(fnc);
        fnc = setTimeout(() => {
          callback.apply(this, args);
        }, ms);
      };
    }
  }
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check p {
    font-size: 15px !important;
    color: #ff2d55;
    font-weight: 400;
  }
}
.text_wrap {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.text_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: 780px;
  border-radius: 16px;
  background: linear-gradient(0deg, #151515, #151515),
    linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
  text-align: Center;
}
.text_box .text_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 32px 32px 0 32px;
  padding: 2px 12px;
}

.text_header .less_than {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 18px;
}

.text_header p {
  position: absolute;
  top: -7px;
  left: 40px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34.75px;
  color: #ffffff;
}
.text_header img {
  margin: auto 0;
  width: 18px;
  height: 18px;
}
.text_header .text_view {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 24px 32px 0 32px;
  padding: 11px 24px;

  width: 476px;
  height: 60px;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  background-color: #ffffff14;
}

.text_view .red_music {
  display: inline-block;
  margin-right: 26px;
  width: 38px;
  height: 38px;
}
.text_view p {
  position: absolute;
  top: 16px;
  left: 86px;
  font-size: 16px;
  font-weight: 350;
  line-height: 23.17px;
  color: #ffffff;
}
.text_view img {
  cursor: pointer;
  margin: auto 0;
  width: 14.4px;
  height: 14.4px;
}
.name_wrap {
  position: relative;
  margin: 0 32px;
  height: 60px;
}

.name_wrap .file_name {
  position: relative;
  padding: 19px 24px;

  width: 476px;
  height: 60px;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  background-color: #ffffff14;

  font-size: 15px;
  font-weight: 500;
  line-height: 60px;
}
.border_red {
  border-color: #ff2d55 !important;
}
.name_wrap input:focus {
  outline: none;
  box-shadow: 0 0px 0px 0 #ffffff4d;
}
.opacity_30 {
  opacity: 30%;
}
.name_wrap img {
  cursor: pointer;
  position: absolute;
  right: 28px;
  width: 14.4px;
  height: 14.4px;
  top: 50%;
  transform: translateY(-50%);
}
.text_warn {
  margin: 5px 24px;
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  color: #ff1150;
}
.text_box .upload_text {
  margin-top: 62px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.96px;
  color: #ffffff;
}
.textarea_wrap {
  position: relative;
}
.textarea_wrap .text_upload {
  margin: 0 auto;
  padding: 18px 24px;
  width: 476px;
  height: 200px;
  border-bottom: 35px solid transparent;
  border-radius: 8px;
}
.textarea_wrap .text_count {
  position: absolute;
  bottom: 17px;
  left: 56px;
  color: #ffffff99;
}
.textarea_wrap .text_count .text_num {
  color: #ffffff;
}
.text_box .voice_title {
  margin: 18px 32px 12px 32px;

  font-size: 16px;
  font-weight: 500;
  line-height: 23.17px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #ffffffde;
}
.text_box .gender_button {
  margin: 0 auto;
  width: 476px;
  height: 52px;
}
.text_box .gender_button button {
  width: 238px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.17px;
}
.gender_button span {
  display: inline-block;
  height: 23px;
  font-size: 16px;
  line-height: 1.2;
}
.gender_button .women_box {
  border-radius: 8px 0 0 8px;
  color: #ffffffde;
  background-color: #ffffff14;
}
.checked_male {
  color: #000000de !important;
  background-color: #ffffffe5 !important;
}
.women_box span::before {
  content: '';
  background-image: url('../../../../public/media/img/women.png');
  background-size: cover;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.gender_button .man_box {
  border-radius: 0px 8px 8px 0px;
  color: #ffffffde;
  background-color: #ffffff14;
}
.man_box span::before {
  content: '';
  background-image: url('../../../../public/media/img/man.png');
  background-size: cover;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.text_box .play_button {
  position: relative;
  margin: 32px auto;
  width: 476px;
  height: 52px;
  border-radius: 8px;
  border: 1px solid #ffffff66;
  opacity: 0.4;
}

.text_box .play_button .play_text {
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-53%, -50%);

  height: 23px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
}
.play_button .play_text::before {
  position: absolute;
  top: 3px;
  right: 70px;

  content: '';
  width: 15px;
  height: 15px;
  background-image: url('../../../../public/media/img/arrow.png');
  background-size: cover;
}
.play_button .stop::before {
  background-image: url('/media/img/mysound/btn_record_stop.svg');
}
.active_button {
  background-color: #ffffff29 !important;
  border-color: #ffffff66 !important;
  opacity: 1 !important;
}

.text_upload img {
  margin: 0 auto;
  width: 82px;
  height: 82px;
}
.text_upload p {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: center;
  color: #ffffff;
}

.next_button {
  position: absolute;
  bottom: 32px;
  right: 32px;

  width: 120px;
  height: 60px;
  border-radius: 8px;
  background-color: #ff2d55;

  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  line-height: 26.06px;
}
@media (max-width: 1536px) {
  .text_box {
    width: 432px;
    height: 624px;
  }
  .text_box .text_header {
    margin: 25.6px 25.6px 0 25.6px;
    padding: 2px 12px;
  }

  .text_header .less_than {
    width: 8px;
    height: 14.4px;
  }

  .text_header p {
    left: 32px;
    font-size: 19.2px;
  }
  .text_header img {
    width: 14.4px;
    height: 14.4px;
  }
  .text_header .text_view {
    margin: 19.2px 25.6px 0 25.6px;
    padding: 8.8px 19.2px;

    width: 380.8px;
    height: 48px;
  }

  .text_view .red_music {
    margin-right: 20.8px;
    width: 30.4px;
    height: 30.4px;
  }
  .text_view p {
    top: 16px;
    left: 86px;
    font-size: 16px;
    font-weight: 350;
    line-height: 23.17px;
  }
  .text_view img {
    width: 14.4px;
    height: 14.4px;
  }
  .name_wrap {
    margin: 0 32px;
    height: 48px;
  }

  .name_wrap .file_name {
    padding: 15.2px 19.2px;

    width: 380.8px;
    height: 48px;

    font-size: 12px;
    line-height: 48px;
  }
  .name_wrap img {
    right: 22.4px;
    width: 14.4px;
    height: 14.4px;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_warn {
    margin: 4px 19.2px;
    font-size: 11.2px;
    line-height: 16.2px;
  }
  .text_box .upload_text {
    margin-top: 49.6px;
    margin-bottom: 19.2px;
    font-size: 16px;
    line-height: 23px;
  }

  .textarea_wrap .text_upload {
    padding: 14.4px 19.2px;
    width: 380.8px;
    height: 160px;
    border-bottom: 28px solid transparent;
  }
  .textarea_wrap .text_count {
    bottom: 13.6px;
    left: 44.8px;
  }

  .text_box .voice_title {
    margin: 14.4px 25.6px 9.6px 25.6px;
    font-size: 12.8px;
    line-height: 18.5px;
  }
  .text_box .gender_button {
    width: 380.8px;
    height: 41.6px;
  }
  .text_box .gender_button button {
    width: 190.4px;
    height: 41.6px;
    font-size: 12.8px;
    line-height: 18.5px;
  }
  .gender_button span {
    height: 18.4px;
    font-size: 12.8px;
    line-height: 1.2;
  }

  .women_box span::before {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .gender_button .man_box {
    border-radius: 0px 6.4px 6.4px 0px;
  }
  .man_box span::before {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .text_box .play_button {
    margin: 25.6px auto;
    width: 380.8px;
    height: 41.6px;
    opacity: 0.4;
  }

  .text_box .play_button .play_text {
    height: 18.4px;
    font-size: 12.8px;
    line-height: 16px;
  }
  .play_button .play_text::before {
    top: 3px;
    right: 54px;
    width: 12px;
    height: 12px;
  }
  .text_upload img {
    margin: 0 auto;
    width: 65.6px;
    height: 65.6px;
  }
  .text_upload p {
    margin-top: 12.8px;
    font-size: 11.2px;
    line-height: 16.2px;
  }

  .next_button {
    position: absolute;
    bottom: 25.6px;
    right: 25.6px;

    width: 96px;
    height: 48px;

    font-size: 14.4px;
    line-height: 20.8px;
  }
}
</style>
