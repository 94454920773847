import axios from 'axios';

// File Upload
const uploadChosenFile = async form => {
  return await axios.post('/api/mysound/uploadChosenFileForSpace', form, {
    header: { 'Content-Type': 'multipart/form-data' }
  });
};

const registMySoundByFile = async ({ userId, spaceId, soundTitle, tempNo, playtime }) => {
  return await axios.post('/api/mysound/registMySoundByFileForSpace', {
    userId,
    spaceId,
    soundTitle,
    tempNo,
    playtime
  });
};

// TTS

const listenTextPreviously = async ({ userId, spaceId, textOfMessage, voiceType, voiceName }) => {
  return await axios.post('/api/mysound/listenTextPreviously', {
    userId,
    spaceId,
    textOfMessage,
    voiceType,
    voiceName
  });
};

const getTTSCount = async ({ userId, spaceId }) => {
  return await axios.post('/api/mysound/getTTSCount', {
    userId,
    spaceId
  });
};
const downloadTTSFile = async ({ userId, spaceId, tempNo }) => {
  return await axios.post('/api/mysound/downloadTTSFile', { userId, spaceId, tempNo });
};

const registMySoundByText = async ({ userId, spaceId, soundTitle, tempNo }) => {
  return await axios.post('/api/mysound/registMySoundByText', { userId, spaceId, soundTitle, tempNo });
};
export {
  uploadChosenFile,
  registMySoundByFile,
  listenTextPreviously,
  getTTSCount,
  downloadTTSFile,
  registMySoundByText
};
