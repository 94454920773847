<template>
  <div class="modal">
    <ConfirmYesNo
      v-if="isStopAndRecordModal"
      :contents="'현재 재생중인 음악을 잠시 멈추고 <br/> 녹음을 시작하시겠습니까?'"
      @cancel-click="onClickStopAndRecordCancel"
      @confirm-click="onClickStopAndRecordConfirm"
    />
    <ConfirmYesNo
      v-if="isBackConfirmModal"
      :contents="'초기화 버튼 클릭 시<br/> 이미 녹음하신 파일은 삭제됩니다.<br/>녹음을 시작하시겠습니까?'"
      @cancel-click="onClickBackConfirmModalCancel"
      @confirm-click="onClickBackConfirmModalConfirm"
    />
    <AlertModal v-if="isAlert" :alertText="alertText" @closeModal="onCloseAlertModal" />
    <audio id="voideReocrderAudro" hidden></audio>
    <div class="modal_box voicerecording_box">
      <div class="voicerecording_title_wrap dp_flex flex_space_between align_center">
        <div>
          <img @click="onClickBackStep" class="less_than cursor" src="/media/img/vector.png" alt="닫기" />
          <span class="voicerecording_title">음성녹음</span>
        </div>
        <div>
          <img class="cursor" @click="onClickClose" src="/media/images/icon_close_24.svg" alt="닫기" />
        </div>
      </div>
      <!-- 녹음 전, 녹음 중, 녹음 완료-->
      <p class="voicerecording_desc" v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3">
        {{ recorderText }} <br />
        <span class="voicerecording_desc_sub" :class="{ ready: recordingStatus === 1 || recordingStatus === 2 }"
          >(최대 1분)</span
        >
      </p>
      <div
        class="voicerecording_progress"
        v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3"
      >
        <!-- 녹음 전-->
        <ul id="voiceRecording" class="voicerecording_before flex_space_between start" v-if="recordingStatus === 1">
          <li class="recording_bar" v-for="(_, index) in barLength" :key="'bar' + index" :id="'bar' + (index + 1)"></li>
        </ul>
        <!--, 녹음 중 -->
        <canvas v-if="recordingStatus === 2" id="audioAnalyser" class="audio_analyser"></canvas>

        <!-- 녹음 완료 -->
        <div class="recording_progress" v-if="recordingStatus === 3">
          <div class="recording_progress_current" :style="{ width: `${progressBarPercent}%` }"></div>
          <div
            class="recording_progress_dot"
            :style="{ left: `${progressBarPercent > 2 ? progressBarPercent - 2 : progressBarPercent}%` }"
          ></div>
          <div class="recording_progress_time flex_space_between" v-if="audioDuration && audioDuration !== Infinity">
            <span class="recording_progress_time_text">{{ currentTimeHHMM }}</span>
            <span class="recording_progress_time_text">{{ durationMMHH }}</span>
          </div>
        </div>
      </div>
      <!-- 녹음 전, 녹음 중, 녹음 완료-->
      <div
        class="recorder_area flex_justify_center align_center"
        v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3"
      >
        <div class="recorder_circle" v-if="recordingStatus === 1 || recordingStatus === 2">
          <!-- 녹음 전  -->
          <button class="recorder_ready_btn" v-if="recordingStatus === 1" @click="startVoiceRecord">
            <img class="recorder_ready_btn_img" src="/media/img/voice_recorder_img.png" alt="녹음" />
          </button>
          <!-- 녹음 중  -->
          <button class="recorder_recording_btn" v-else-if="recordingStatus === 2" @click="onClickStopVoiceRecord(3)">
            <img class="recorder_recording_btn_img" src="/media/img/voice_recording_img.png" alt="녹음" />
          </button>
        </div>

        <!-- 녹음 완료 -->
        <div class="recording_btn_group flex_space_between" v-if="recordingStatus === 3">
          <button class="recording_stop_btn flex_justify_center align_center" @click="onClickVoicePreveiw">
            <img
              :src="voiceRecorderIsPlay ? '/media/img/voice_recording_img.png' : '/media/img/recording_btn.png'"
              class="recording_stop_btn_img"
              :class="{ pause: voiceRecorderIsPlay }"
              alt="중지"
            />
          </button>
          <button class="recording_replay_btn flex_justify_center align_center" @click="onClickBackStep1">
            <img src="/media/img/replay_btn.png" class="recording_replay_btn_img" alt="중지" />
          </button>
        </div>
      </div>
      <p class="recorder_count" v-if="recordingStatus === 2">
        {{ getRecorderCount }}
      </p>
      <!-- 녹음 완료, 파일명 입력 -->
      <div class="filename_wrap" v-if="recordingStatus === 4">
        <h3 class="filename_title">파일명을 설정해주세요.</h3>
        <div class="w_90 input_wrap">
          <input
            class="w_100 mlr_auto filename_input"
            size="10"
            type="text"
            id="fileName"
            @input="onInputTextFileName"
            placeholder="ex) 오프닝용 멘트"
          />
          <img
            v-if="fileName.length > 0"
            class="input_delete_img cursor"
            src="/media/images/icon_close_24.svg"
            @click="onClickFileNameDelete"
            alt="닫기"
          />
        </div>
        <p v-if="isWarn" class="w_90 text_warn">파일명은 띄어쓰기 포함 10글자까지만 가능합니다.</p>
      </div>
      <button
        v-if="recordingStatus === 3 || recordingStatus === 4"
        :disabled="(fileName.length === 0 || fileName.length > 10) && recordingStatus === 4"
        class="recroding_complet_btn"
        @click="onClickUploadVoiceFile"
      >
        {{ recordingCompleteBtn }}
      </button>
    </div>
  </div>
</template>
<script>
import { uploadChosenFileApi, registMySoundByFileApi } from '@/service/api/mySoundApi';
import AlertModal from '../Space/AlertModal.vue';
import ConfirmYesNo from '../../common/ConfirmYesNo.vue';
export default {
  name: 'VoiceRecording',
  emits: ['close-voicerecording'],
  data() {
    return {
      barLength: 55,
      recordingStatus: 1, // 1:녹음 전, 2:녹음 중, 3:녹음 완료, 4:파일명,
      fileName: '',
      audioChuncks: [],
      mp3Blob: null,
      audioRecorder: null,
      audioStream: null,
      file: null,
      audioEle: null,
      audioDuration: 0,
      currentTime: 0,
      fileResult: {
        fileOnServer: null,
        playtime: -1
      },
      recorderCount: 0,
      recorderInterval: -1,
      voiceRecorderIsPlay: false,
      progressBarPercent: 0,
      isApiCall: false,
      alertText: '파일이 등록되었습니다.',
      isSuccessRegistMySound: false,
      isAlert: false,
      isStopAndRecordModal: false,
      isBackConfirmModal: false,
      canvasObj: {},
      timeOffset: 70,
      refId: -1,
      now: -1,
      audioContext: null,
      audioContent: null,
      streamSource: null,
      isWarn: false
    };
  },
  mounted() {
    this.audioEle = document.querySelector('#voideReocrderAudro');
    this.canvasObj.bars = [];
    if (this.audioEle) {
      this.audioEle.addEventListener('timeupdate', this.onVoiceRecorderTimeUpdate);
      this.audioEle.addEventListener('ended', this.onVoiceRecorderEnded);
    }
  },
  components: {
    AlertModal,
    ConfirmYesNo
  },
  methods: {
    /**
     * @description 모달닫기
     * @author CHOI DAE GEON
     */
    onClickClose() {
      this.$emit('close-voicerecording');
    },
    /**
     * @description 파일명 input event
     * @param $event
     * @author CHOI DAE GEON
     */
    onInputTextFileName($event) {
      if ($event.target) {
        this.fileName = $event.target.value;
        if (this.fileName.length > 10) {
          this.isWarn = true;
          $event.target.value = $event.target.value.slice(0, 10);
          this.fileName = $event.target.value.slice(0, 10);
          setTimeout(() => {
            this.isWarn = false;
          }, 1000);
        }
      }
    },
    /**
     * @description 파일명 삭제
     * @author CHOI DAE GEON
     */
    onClickFileNameDelete() {
      const fileNameEle = document.querySelector('#fileName');
      if (fileNameEle) {
        fileNameEle.value = '';
        this.fileName = '';
      }
    },
    /**
     * @description 미리듣기
     * @author CHOI DAE GEON
     */
    onClickVoicePreveiw() {
      const audioEle = document.querySelector('#voideReocrderAudro');
      if (audioEle.paused) {
        this.currentTime = 0;
        audioEle
          .play()
          .then(() => {
            this.voiceRecorderIsPlay = true;
          })
          .catch(() => {
            this.voiceRecorderIsPlay = false;
          });
      } else {
        audioEle.pause();
        this.voiceRecorderIsPlay = false;
      }
    },
    /**
     * @description ended event
     * @author CHOI DAE GEON
     */
    onVoiceRecorderEnded() {
      this.voiceRecorderIsPlay = false;
    },
    /**
     * @description timeupdate event
     * @author CHOI DAE GEON
     */
    onVoiceRecorderTimeUpdate($event) {
      if ($event) {
        this.currentTime = Math.floor($event.target.currentTime);
        if ($event.target.duration !== Infinity && !isNaN($event.target.duration)) {
          this.audioDuration = Math.floor($event.target.duration);
        }

        this.progressBarPercent = (this.currentTime / this.audioDuration) * 100;
      }
    },
    /**
     * @description 목소리 녹음 시작
     * @author CHOI DAE GEON
     */
    startVoiceRecord() {
      if (navigator.mediaDevices) {
        // 스트리밍 재생인 경우
        if (!this.$VideoPlayer.getPaused()) {
          this.isStopAndRecordModal = true;
        } else {
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
              this.audioStream = stream;
              this.audioRecorder = new MediaRecorder(stream);

              this.audioRecorder.addEventListener('dataavailable', this.onDataAvailable);

              this.audioRecorder.start();

              // AudioContext

              this.recorderInterval = setInterval(() => {
                if (this.recorderCount < 60) {
                  this.recorderCount += 1;
                } else {
                  this.clearCanvas();
                  this.recordingStatus = 3;
                  this.audioStream.getTracks().forEach(track => track.stop());
                  this.audioDuration = this.recorderCount;
                  clearInterval(this.recorderInterval);
                }
              }, 1000);
              this.recordingStatus = 2;
              this.$nextTick(() => {
                this.now = parseInt(performance.now()) / this.timeOffset;

                // canvas init
                this.canvasInit();
                this.canvasObj.ctx.beginPath();

                // canvan loop
                this.canvasLoop();
              });
            })
            .catch(err => {
              console.log('Error accessing the microphone: ' + err);
            });
        }
      }
    },
    /**
     * @description 목소리 녹음 중지
     * @author CHOI DAE GEON
     */
    onClickStopVoiceRecord(step = 3) {
      this.audioStream.getTracks().forEach(track => track.stop());
      this.audioDuration = this.recorderCount;
      clearInterval(this.recorderInterval);
      this.recorderCount = 0;

      if (this.recordingStatus === 2) {
        this.clearCanvas();
      }
      this.recordingStatus = step;
    },
    /**
     * @description Data Available Event
     * @param event
     */
    onDataAvailable(event) {
      this.audioChuncks.push(event.data);
      const blob = new Blob(this.audioChuncks, { type: 'application/octet-stream' });
      this.mp3Blob = blob;
      const audioEle = document.querySelector('#voideReocrderAudro');
      const audioUrl = URL.createObjectURL(blob);

      audioEle.src = audioUrl;
    },
    /**
     * @description 파일 업로드
     * @author CHOI DAE GEON
     */
    async onClickUploadVoiceFile() {
      if (!this.isApiCall) {
        this.isApiCall = true;
        if (this.recordingStatus === 3) {
          const formData = new FormData();

          formData.append('userId', this.$cookies.get('userId'));
          formData.append('spaceId', this.$cookies.get('spaceId'));
          formData.append('fileToUpload', this.mp3Blob);
          try {
            const { data, status } = await uploadChosenFileApi(formData);
            if (status === 200) {
              this.audioChuncks = [];
              this.recordingStatus = 4;
              const { result } = data;
              const { fileOnServer, tempNo } = result;
              this.fileResult.fileOnServer = fileOnServer;
              this.fileResult.playtime = this.audioDuration;
              this.fileResult.tempNo = tempNo;
            }
          } catch (error) {
            if (error.response) {
              const { status, data } = error.response;
              if (status === 500) {
                console.log('uploadChosenFileApi error : ', error);
              } else if (status === 400) {
                const { resultMsg } = data;
                this.alertText = resultMsg;
                this.isAlert = true;
              }
            }
          } finally {
            this.isApiCall = false;
            this.audioEle.pause();
            this.audioEle.src = null;
            this.voiceRecorderIsPlay = false;
          }

          // finally
        } else if (this.recordingStatus === 4) {
          try {
            const { status } = await registMySoundByFileApi(
              this.$cookies.get('userId'),
              this.$cookies.get('spaceId'),
              this.fileName,
              this.fileResult.tempNo,
              this.fileResult.playtime
            );
            if (status === 200) {
              // 성공 후 로직 작성 필요
              this.alertText = `파일이 등록되었습니다.`;
              this.isAlert = true;
              this.isSuccessRegistMySound = true;
            }
          } catch (error) {
            if (error.response) {
              const { status, data } = error.response;
              if (status === 500) {
                // 업로드 실패시 작성 필요
                this.isAlert = true;
                this.alertText = `파일 등록에 실패하였습니다.`;
              } else if (status === 400) {
                const { resultMsg } = data;
                this.isAlert = true;
                this.alertText = resultMsg;
              }
              this.isSuccessRegistMySound = false;
            }
          } finally {
            this.isApiCall = false;
          }
        }
      }
    },
    /**
     * @description 1단계로 이동
     * @author CHOI DAE GEON
     */
    onClickBackStep1() {
      this.isBackConfirmModal = true;
    },
    /**
     * @description 뒤로가기
     * @author CHOI DAE GEON
     */
    onClickBackStep() {
      if (this.recordingStatus === 1) {
        this.$emit('close-voicerecording');
      } else if (this.recordingStatus === 2) {
        this.onClickStopVoiceRecord(1);
        this.audioChuncks = [];
      } else if (this.recordingStatus === 3) {
        this.onClickBackStep1();
        this.audioChuncks = [];
      } else if (this.recordingStatus === 4) {
        this.recordingStatus = 3;
        this.fileName = '';
      }
    },
    /**
     * @description 등록완료 모달 Close
     * @author CHOI DAE GEON
     */
    onCloseAlertModal() {
      this.isAlert = false;
      if (this.isSuccessRegistMySound) {
        this.$emit('close-voicerecording');
      }
    },
    /**
     * @description 녹음 중지 Confirm Modal cancel
     * @author CHOI DAE GEON
     */
    onClickStopAndRecordCancel() {
      this.isStopAndRecordModal = false;
    },
    /**
     * @description 녹음 중지 Confirm Modal Confirm
     * @author CHOI DAE GEON
     */
    onClickStopAndRecordConfirm() {
      this.isStopAndRecordModal = false;
      this.$VideoPlayer.onPause();
      this.$store.commit('setPlay', false);
      this.startVoiceRecord();
    },
    /**
     * @description 녹음완료 후 뒤로가기 Confrim Modal Cancel
     * @author CHOI DAE GEON
     */
    onClickBackConfirmModalCancel() {
      this.isBackConfirmModal = false;
    },
    /**
     * @description 녹음완료 후 뒤로가기 Confrim Modal Confirm
     * @author CHOI DAE GEON
     */
    onClickBackConfirmModalConfirm() {
      this.clearCanvas();
      this.recordingStatus = 1;
      this.voiceRecorderIsPlay = false;
      this.audioDuration = 0;
      this.audioChuncks = [];
      this.audioEle.pause();
      this.recorderCount = 0;

      this.now = -1;
      this.isBackConfirmModal = false;
    },
    /**
     * @description canvas init
     * @author CHOI DAE GEON
     */
    canvasInit() {
      const voicerecordingProgress = document.querySelector('.voicerecording_progress');
      const width = voicerecordingProgress.clientWidth;
      const height = voicerecordingProgress.clientHeight;
      // const progressBarWidth = doc
      this.canvasObj.canvas = document.querySelector('#audioAnalyser');
      this.canvasObj.ctx = this.canvasObj.canvas.getContext('2d');
      this.canvasObj.width = width;
      this.canvasObj.height = height;

      this.canvasObj.canvas.width = this.canvasObj.width * window.devicePixelRatio;
      this.canvasObj.canvas.height = this.canvasObj.height * window.devicePixelRatio;
      this.canvasObj.canvas.style.width = this.canvasObj.width + 'px';
      this.canvasObj.canvas.style.height = this.canvasObj.height + 'px';
      const widthDevicePixcelRatio = document.body.clientWidth > 1920 ? 1 : window.devicePixelRatio;
      this.canvasObj.ctx.scale(widthDevicePixcelRatio, window.devicePixelRatio);
    },
    /**
     * @description canvas loop
     * @author CHOI DAE GEON
     */
    canvasLoop() {
      this.canvasObj.ctx.clearRect(0, 0, this.canvasObj.canvas.width, this.canvasObj.canvas.height);

      if (parseInt(performance.now() / this.timeOffset) > this.now) {
        this.now = parseInt(performance.now() / this.timeOffset);

        const freq = Math.floor(Math.random() * 64) + 16;

        this.canvasObj.bars.push({
          x: this.canvasObj.width,
          y: this.canvasObj.height / 2 - freq / 2,
          height: freq,
          width: 2
        });
      }

      this.canvansDraw();
      this.refId = requestAnimationFrame(this.canvasLoop);
    },
    /**
     * @description canvas draw
     * @author CHOI DAE GEON
     */
    canvansDraw() {
      for (let i = 0; i < this.canvasObj.bars.length; i++) {
        const bar = this.canvasObj.bars[i];
        this.canvasObj.ctx.fillStyle = `rgba(255, 45, 85, 1)`;
        this.canvasObj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height);
        bar.x = bar.x - 2;

        if (bar.x < 1) {
          this.canvasObj.bars.splice(i, 1);
        }
      }
    },
    /**
     * @description Clear Canvas
     * @author CHOI DAE GEONs
     */
    clearCanvas() {
      this.canvasObj.bars = [];
      this.canvasObj.ctx.clearRect(0, 0, this.canvasObj.canvas.width, this.canvasObj.canvas.height);
      this.canvasObj.ctx.beginPath();
      cancelAnimationFrame(this.refId);
      this.now = -1;
    }
  },
  computed: {
    recorderText() {
      if (this.recordingStatus === 1) {
        return `음성 녹음을 시작해주세요.`;
      } else if (this.recordingStatus === 2) {
        return `음성 녹음이 진행중입니다.`;
      } else if (this.recordingStatus === 3) {
        return `음성 녹음이 완료되었습니다.`;
      }

      return '';
    },
    recordingCompleteBtn() {
      if (this.recordingStatus === 3) {
        return '다음';
      }

      return '완료';
    },
    durationMMHH() {
      if (this.audioDuration) {
        const hh =
          Math.floor(this.audioDuration / 60) > 9
            ? Math.floor(this.audioDuration / 60)
            : `0${Math.floor(this.audioDuration / 60)}`;
        const mm =
          Math.floor(this.audioDuration % 60) > 9
            ? Math.floor(this.audioDuration % 60)
            : `0${Math.floor(this.audioDuration % 60)}`;
        return `${hh}:${mm}`;
      }
      return '01:00';
    },
    getRecorderCount() {
      const hh =
        Math.floor(this.recorderCount / 60) > 9
          ? Math.floor(this.recorderCount / 60)
          : `0${Math.floor(this.recorderCount / 60)}`;
      const mm =
        Math.floor(this.recorderCount % 60) > 9
          ? Math.floor(this.recorderCount % 60)
          : `0${Math.floor(this.recorderCount % 60)}`;
      return `${hh}:${mm}`;
    },
    currentTimeHHMM() {
      if (this.currentTime) {
        const hh =
          Math.floor(this.currentTime / 60) > 9
            ? Math.floor(this.currentTime / 60)
            : `0${Math.floor(this.currentTime / 60)}`;
        const mm =
          Math.floor(this.currentTime % 60) > 9
            ? Math.floor(this.currentTime % 60)
            : `0${Math.floor(this.currentTime % 60)}`;

        return `${hh}:${mm}`;
      }
      return '00:00';
    }
  },
  destroyed() {
    if (this.audioRecorder) {
      this.audioRecorder.removeEventListener('dataavailable', this.onDataAvailable);
    }

    this.audioEle = document.querySelector('#voideReocrderAudro');
    if (this.audioEle) {
      this.audioEle.removeEventListener('timeupdate', this.onVoiceRecorderTimeUpdate);
    }

    if (this.audioStream) {
      this.audioStream.getTracks().forEach(track => track.stop());
    }
  }
};
</script>
<style scoped src="@/assets/css/modal/voicerecording/voicerecording.css"></style>
