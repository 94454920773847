<template>
  <div>
    <VoiceRecording v-if="isVoiceRecording" @close-voicerecording="onClickVoiceRecording" />

    <FileUploadModal v-show="isFileUpload" @close-modal="onFileModal" />
    <TextUploadModal v-show="isTextUpload" @close-modal="onTextModal" />
    <ConfirmYesNo
      v-if="isDeleteModal"
      :contents="'해당 파일을 삭제하시겠습니까?'"
      @cancel-click="onClickCancelFileDelete"
      @confirm-click="onClickConfirmFileDelete"
    />
    <ConfirmYesNo
      v-if="isStopAndPlayModalMySound"
      :contents="'현재 재생중인 음악을 잠시 멈추고 <br/> 선택하신 음악을 재생하시겠습니까?'"
      @cancel-click="onClickStopAndPlayCancelMySound"
      @confirm-click="onClickStopAndPlayConfirmMySound"
    />
    <ConfirmYesNo
      v-if="isStopAndPlayModalBrand"
      :contents="'현재 재생중인 음악을 잠시 멈추고 <br/> 선택하신 음악을 재생하시겠습니까?'"
      @cancel-click="onClickStopAndPlayCancelBrand"
      @confirm-click="onClickStopAndPlayConfirmBrand"
    />
    <AlertModal v-if="isAlert" :alertText="alertText" @closeModal="onCloseAlertModal" />
    <AlertBrandMusic v-if="isAlertBrandMusic" @closeModal="isAlertBrandMusic = false" />
    <MusicAlert v-if="musicAlertStatus" @closeAlert="musicAlertStatus = false" />
    <ReleaseAlert v-if="isReleaseAlert" @closeModal="isReleaseAlert = false" />
    <SetTimeBrandSound
      v-if="isSetTime"
      :broadcasttype="broadcastType"
      @closeModal="isSetTime = false"
      @getList="setBrnadMySoundList"
      :musicInfo="musicInfo"
    />
    <div class="brand_modal modal1">
      <div class="brand_modal_box modal_box broadcast">
        <div class="brand_modal_title modal_title">
          <!-- 2024.12.19 백엔드 이슈로 인하여 방송/안내 => 브랜드 음원 설정 -->
          <h2>방송/안내</h2>
          <!--<h2>브랜드 음원 설정</h2>-->
          <div class="modal_close_btn" @click="$emit('closeBrandMusic')">
            <img src="/media/images/icon_close_24.svg" alt="" />
          </div>
        </div>
        <!-- 2024.12.19 백엔드 이슈로 인하여 메뉴삭제 -->
        <ul class="soundmodal_menu" v-if="isbrand">
          <li class="soundmodal_menu_item" :class="activeTabIndex === 0 ? 'active' : ''">
            <a
              tabindex="0"
              class="cursor soundmodal_menu_item_text"
              :class="activeTabIndex === 0 ? 'active' : ''"
              @click="activeTabIndex = 0"
              >브랜드</a
            >
          </li>
          <li class="soundmodal_menu_item" :class="activeTabIndex === 1 ? 'active' : ''">
            <a
              tabindex="0"
              class="cursor soundmodal_menu_item_text"
              :class="activeTabIndex === 1 ? 'active' : ''"
              @click="activeTabIndex = 1"
              >마이</a
            >
          </li>
        </ul>
        <!--######################### 브랜드 음원 설정 #########################-->
        <div v-if="activeTabIndex === 0">
          <video id="brandvideo" hidden playsinline></video>
          <div class="table_top">
            <table class="brand_table">
              <thead>
                <tr>
                  <th class="brand_table_thead">브랜드 음원명</th>
                  <th class="brand_table_thead">시간설정</th>
                  <th class="brand_table_thead">재생여부</th>
                  <th class="brand_table_thead">듣기</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="brand_scroll_body">
            <table class="brand_table">
              <tbody>
                <template v-for="(item, index) in brandMusic">
                  <tr :key="index">
                    <td :class="{ common_dim: item.spaceId == null }">
                      <div :class="{ common_icon: item.spaceId == null }" @click="isAlertBrandMusic = true">
                        <div v-if="item.spaceId == null">
                          <p>공통</p>
                        </div>
                        <p class="music_title" v-html="item.brandMusicNm" />
                      </div>
                    </td>
                    <td class="select-time" :class="{ common_dim: item.spaceId == null }">
                      <div
                        class="select-box-area"
                        v-if="item.playType === 'R'"
                        @mouseover="showIcon($event, item)"
                        @mouseleave="hideIcon($event)"
                        @click="timeSetting(item)"
                      >
                        <span class="play_type_r">반복</span><span>{{ item.startTime }}부터~{{ item.endTime }}까지</span
                        ><span>{{ item.repeatTime }}시간마다</span><img src="/media/img/icon_update.svg" />
                      </div>
                      <div
                        class="select-box-area"
                        v-else-if="item.playType === 'S'"
                        @mouseover="showIcon($event, item)"
                        @mouseleave="hideIcon($event)"
                        @click="timeSetting(item)"
                      >
                        <span class="play_type_s">지정</span><span>{{ item.selectTime }}</span
                        ><img src="/media/img/icon_update.svg" alt="" />
                      </div>
                      <div v-else><button @click="timeSetting(item)">설정하기</button></div>
                    </td>
                    <td :class="{ common_dim: item.spaceId == null }">
                      <div class="alarm_box">
                        <span :class="{ active1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                        <span
                          class="move"
                          :class="{ active1_1: item.playAt === 'Y' }"
                          @click="updateBrandMusicPlayAt(item)"
                        ></span>
                      </div>
                    </td>
                    <td>
                      <div class="pre_play">
                        <img
                          v-bind:id="'img' + index"
                          :src="activeIndex === index ? '/media/img/brand_Pause.svg' : '/media/img/brand_Play.svg'"
                          @click="onCheckStreamingBrand(item, index)"
                        />
                        <div class="sound_updown">
                          <span hidden></span>
                          <span v-bind:style="{ width: $store.state.playNav + '%' }" hidden></span>
                          <input
                            v-bind:id="'seekslider' + index"
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            value="0"
                            hidden
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <!--######################### 마이사운드 설정 #########################-->
        <div v-if="activeTabIndex === 1">
          <video id="mysoundvideo" hidden playsinline></video>
          <div class="table_top" v-if="mysoundmusicList.length > 0">
            <table class="brand_table">
              <thead>
                <tr>
                  <th class="brand_table_thead mysoundtable_th1">파일명</th>
                  <th class="brand_table_thead mysoundtable_th2">시간설정</th>
                  <th class="brand_table_thead mysoundtable_th3">재생여부</th>
                  <th class="brand_table_thead mysoundtable_th4">듣기</th>
                  <th class="brand_table_thead mysoundtable_th5">삭제</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="brand_scroll_body" v-if="mysoundmusicList.length > 0">
            <table class="brand_table">
              <tbody>
                <tr v-for="(item, index) in mysoundmusicList" :key="item.brandMusicId">
                  <td class="mysound_td1">
                    <div>
                      <p class="music_title">{{ item.brandMusicNm }}</p>
                    </div>
                  </td>
                  <td class="mysound_td2 select-time">
                    <div
                      class="select-box-area mysound"
                      v-if="item.playType === 'R'"
                      @mouseover="showIcon($event, item)"
                      @mouseleave="hideIcon($event)"
                      @click="timeSetting(item, 'mysound')"
                    >
                      <span class="play_type_r">반복</span><span>{{ item.startTime }}부터~{{ item.endTime }}까지</span>
                      <span>{{ item.repeatTime }}시간마다</span><img src="/media/img/icon_update.svg" />
                    </div>
                    <div
                      class="select-box-area mysound"
                      v-else-if="item.playType === 'S'"
                      @mouseover="showIcon($event, item)"
                      @mouseleave="hideIcon($event)"
                      @click="timeSetting(item, 'mysound')"
                    >
                      <span class="play_type_s">지정</span><span>{{ item.selectTime }}</span
                      ><img src="/media/img/icon_update.svg" />
                    </div>
                    <div class="mysound" v-else @click="timeSetting(item, 'mysound')">
                      <button>설정하기</button>
                    </div>
                  </td>
                  <td class="mysound_td3">
                    <div class="alarm_box mysound" @click="updateBrandMusicPlayAt(item)">
                      <span :class="item.playAt === 'Y' ? 'active1' : ''"></span>
                      <span class="move " :class="item.playAt === 'Y' ? 'active1_1' : ''"></span>
                    </div>
                  </td>
                  <td class="mysound_td4">
                    <div class="pre_play">
                      <img
                        :id="`mysoundimg${index}`"
                        :src="activeMySoundIndex === index ? '/media/img/brand_Pause.svg' : '/media/img/brand_Play.svg'"
                        @click="onCheckStreamingMySound(item, index)"
                      />
                    </div>
                  </td>
                  <td class="mysound_td5">
                    <div class="delete_icon" @click="onClickDeletModal(item)">
                      <img :id="'delete_icon_img1'" src="/media/img/icon_delete.png" />
                    </div>
                  </td>
                </tr>
                <!-- 스크롤시 "+" 버튼에 가려서 Dummy row 추가-->
                <tr class="dummy" v-if="mysoundmusicList.length > 0"></tr>
              </tbody>
            </table>
          </div>
          <div v-else class="soundmodal_no_list">
            <img class="soundmodal_no_list_icon" src="/media/img/icon_nosoundlist.png" alt="마아사운드없음" />
            <p class="soundmodal_no_list_text">
              등록된 파일이 없습니다. <br />
              하단의 +버튼을 눌러 파일을 등록해주세요.
            </p>
          </div>
        </div>
        <!--######################### 마이사운드 추가 #########################-->
        <div
          class="add_mysound cursor"
          v-if="activeTabIndex === 1"
          @click="onClickMySoundAdd"
          v-click-outside="onMySoundOutSideClick"
        >
          <div class="add_mysound_menu" v-if="isMySoundMenu">
            <ul class="add_mysound_menu_list">
              <li class="add_mysound_menu_list_item first cursor" @click="fileModalOpen">
                <div class="add_mysound_menu_list_itemiconwrap">
                  <img
                    class="add_mysound_menu_list_itemicon_upload cursor"
                    src="/media/img/icon_upload.png"
                    alt="파일업로드"
                  />
                </div>

                <span class="add_mysound_menu_list_itemtext">파일 업로드</span>
              </li>
              <li class="add_mysound_menu_list_item cursor" @click="onClickVoiceRecording">
                <div class="add_mysound_menu_list_itemiconwrap">
                  <img
                    class="add_mysound_menu_list_itemicon_recorder cursor"
                    src="/media/img/icon_recorder.png"
                    alt="파일업로드"
                  />
                </div>

                <span class="add_mysound_menu_list_itemtext">음성 녹음</span>
              </li>
              <li class="add_mysound_menu_list_item last cursor" @click="textModalOpen">
                <div class="add_mysound_menu_list_itemiconwrap">
                  <img
                    class="add_mysound_menu_list_itemicon_text cursor"
                    src="/media/img/icon_text.png"
                    alt="텍스트 입력"
                  />
                </div>

                <span class="add_mysound_menu_list_itemtext">텍스트 입력</span>
              </li>
            </ul>
          </div>
          <img class="add_mysound_plusimg cursor" src="/media/img/mysound_add.png" alt="마이사운드추가" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import AlertBrandMusic from '@/components/modal/Player/AlertBrandMusic.vue';
import ReleaseAlert from '@/components/modal/Player/releaseAlert.vue';
import MusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import SetTimeBrandSound from '@/components/modal/buildingSpace/setTime.vue';
import AlertModal from '../Space/AlertModal.vue';
import ConfirmYesNo from '../../common/ConfirmYesNo.vue';
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';
import $ from 'jquery';
import { deleteMySoundApi } from '@/service/api/mySoundApi';
import VoiceRecording from './VoiceRecording.vue';
import FileUploadModal from './FileUploadModal.vue';
import TextUploadModal from './TextUploadModal.vue';

export default defineComponent({
  data() {
    return {
      broadcastType: 'brand',
      musicAlertStatus: false,
      isSetTime: false,
      isAlertBrandMusic: false,
      isReleaseAlert: false,
      selectTime: 'Y',
      repeatTime: 'N',
      musicInfo: {
        brandMusicId: String,
        fileName: String,
        soundTitle: String,
        mySoundDetail: {
          brandSpaceMusicId: String,
          selectTime: '09:00',
          repeatTime: '1',
          startTime: '09:00',
          playType: String
        }
      },
      playUrl: null,
      config: {
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      },
      activeIndex: -1,
      activeMySoundIndex: -1,
      activeTabIndex: 0,
      alertText: '파일은 최대 10개까지만 등록 가능합니다.',
      isAlert: false,
      isDeleteModal: false,
      isStopAndPlayModalMySound: false,
      isStopAndPlayModalBrand: false,
      isMySoundMenu: false,
      selectItem: {
        userId: null,
        brandMusicId: null,
        mySoundDetail: {
          brandSpaceMusicId: -1
        }
      },
      selectPlayMySounditem: null,
      selectPlayBranditem: null,
      isVoiceRecording: false,
      isFileUpload: false,
      isTextUpload: false
    };
  },
  props: {
    brandMusic: Array,
    isbrand: {
      type: Boolean,
      required: false,
      default: false
    },
    mysoundmusicList: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    MusicAlert,
    SetTimeBrandSound,
    AlertBrandMusic,
    ReleaseAlert,
    AlertModal,
    ConfirmYesNo,
    VoiceRecording,
    FileUploadModal,
    TextUploadModal
  },
  created() {
    if (!this.isbrand) {
      this.activeTabIndex = 1;
    }
  },
  methods: {
    showIcon(e, music) {
      if (music.spaceId != null) $(e.currentTarget).addClass('on');
    },
    hideIcon(e) {
      $(e.currentTarget).removeClass('on');
    },
    timeSetting(music, broadcastType = 'brand') {
      this.broadcastType = broadcastType;
      // 지정/반복 시간 설정 팝업
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        this.musicInfo.brandMusicId = music.brandMusicId;
        this.musicInfo.mySoundDetail.playType = music.playType;
        this.musicInfo.mySoundDetail.selectTime = music.selectTime;
        this.musicInfo.mySoundDetail.repeatTime = music.repeatTime;
        this.musicInfo.mySoundDetail.startTime = music.startTime;
        this.musicInfo.mySoundDetail.endTime = music.endTime;
        this.isSetTime = true;
      }
    },
    endMySoundEvent() {
      this.activeMySoundIndex = -1;
    },
    endBrandVideoEvent() {
      this.activeIndex = -1;
    },
    playMuic(url, index) {
      const bVideo = document.querySelector(`#bVideo${index}`);
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084/stream/getMusic?' + url
          : '/stream/getMusic?' + url;
      if (Hls.isSupported()) {
        let hls = null;
        if (hls != null) hls.destroy(); // removed all listeners
        hls = new Hls(this.config);
        hls.loadSource(tg);
        hls.attachMedia(bVideo);
        bVideo.play();
      }
    },
    updateBrandMusicPlayAt(music, type = 'brand') {
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        if (music.playAt === 'N' && music.playType === null) {
          this.isReleaseAlert = true;
        } else {
          music.playAt === 'Y' ? (music.playAt = 'N') : (music.playAt = 'Y');
          const brandMusicId = music.brandMusicId;
          const spaceId = VueCookies.get('spaceId');
          const aToken = VueCookies.get('aToken');
          const headers = { 'X-AUTH-TOKEN': aToken };

          axios
            .post('/api/player/updateBrandSpaceMusicPlayAt', { spaceId, brandMusicId }, { headers })
            .then(res => {
              if (res.data.resultCd === '0000') {
                this.$emit('getList');
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    },
    onCloseAlertModal() {
      this.isAlert = false;
    },
    setBrnadMySoundList(type = 'brand') {
      if (type === 'mysound') {
        // 마이사운드 리스트 조회
        this.$store.dispatch('player/settingMySoundList', {
          userId: this.$cookies.get('userId'),
          spaceId: this.$cookies.get('spaceId')
        });
      }

      this.$emit('getList');
    },
    onClickDeletModal(item) {
      this.selectItem.userId = this.$cookies.get('userId');
      this.selectItem.brandMusicId = item.soundBrandMusicId;
      this.selectItem.mySoundDetail.brandSpaceMusicId = item.brandSpaceMusicId;
      this.isDeleteModal = true;
    },
    onClickCancelFileDelete() {
      this.clearSelectItem();
      this.isDeleteModal = false;
    },
    async onClickConfirmFileDelete() {
      try {
        const { status } = await deleteMySoundApi(
          this.selectItem.userId,
          this.selectItem.brandMusicId,
          this.selectItem.mySoundDetail
        );
        if (status === 200) {
          this.alertText = `삭제 되었습니다`;
          this.isAlert = true;
          this.setBrnadMySoundList('mysound');
          this.getMySoundEle().src = null;
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          this.alertText = `삭제에 실패하였습니다`;
          this.isAlert = true;
          this.setBrnadMySoundList('mysound');
        }
      } finally {
        this.isDeleteModal = false;
      }
    },
    clearSelectItem() {
      this.selectItem = {
        userId: null,
        brandMusicId: null,
        mySoundDetail: {
          brandSpaceMusicId: -1
        }
      };
    },
    onCheckStreamingMySound(item, index) {
      this.selectPlayMySounditem = item;
      if (index === this.activeMySoundIndex) {
        this.activeMySoundIndex = -1;
        this.getMySoundEle().pause();
      } else {
        // 재생중일 경우
        if (!this.$VideoPlayer.getPaused()) {
          this.isStopAndPlayModalMySound = true;
        } else {
          this.onPlayMySound();
        }
      }
    },
    onCheckStreamingBrand(item, index) {
      this.selectPlayBranditem = item;
      if (index === this.activeIndex) {
        this.activeIndex = -1;
        this.getBrandEle().pause();
      } else {
        // 재생중일 경우
        if (!this.$VideoPlayer.getPaused()) {
          this.isStopAndPlayModalBrand = true;
        } else {
          this.onPlayBrand();
        }
      }
    },
    onPlayMySound() {
      const url = this.selectPlayMySounditem.brandMusicId;

      const findIndex = this.mysoundmusicList.findIndex(item => item.brandMusicId === url);
      if (findIndex > -1) {
        this.activeMySoundIndex = findIndex;
      }
      const mySoundVideo = this.getMySoundEle();
      mySoundVideo.addEventListener('ended', this.endMySoundEvent, { once: true });
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084/stream/getMusic?' + url
          : '/stream/getMusic?' + url;
      if (Hls.isSupported()) {
        let hls = null;
        if (hls != null) hls.destroy(); // removed all listeners
        hls = new Hls(this.config);
        hls.loadSource(tg);
        hls.attachMedia(mySoundVideo);
        mySoundVideo.play();
      }
    },
    onPlayBrand() {
      const url = this.selectPlayBranditem.brandMusicId;
      const findIndex = this.brandMusic.findIndex(item => item.brandMusicId === url);
      if (findIndex > -1) {
        this.activeIndex = findIndex;
      }
      const brandVideo = this.getBrandEle();
      brandVideo.addEventListener('ended', this.endBrandVideoEvent, { once: true });
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084/stream/getMusic?' + url
          : '/stream/getMusic?' + url;
      if (Hls.isSupported()) {
        let hls = null;
        if (hls != null) hls.destroy(); // removed all listeners
        hls = new Hls(this.config);
        hls.loadSource(tg);
        hls.attachMedia(brandVideo);
        brandVideo.play();
      }
    },
    getBrandEle() {
      return document.querySelector('#brandvideo');
    },
    getMySoundEle() {
      return document.querySelector('#mysoundvideo');
    },
    onClickStopAndPlayConfirmMySound() {
      this.$VideoPlayer.onPause();
      this.$store.commit('setPlay', false);
      this.isStopAndPlayModalMySound = false;
      this.onPlayMySound();
    },
    onClickStopAndPlayCancelMySound() {
      this.selectPlayMySounditem = null;
      this.isStopAndPlayModalMySound = false;
    },
    onClickStopAndPlayConfirmBrand() {
      this.$VideoPlayer.onPause();
      this.$store.commit('setPlay', false);
      this.isStopAndPlayModalBrand = false;
      this.onPlayBrand();
    },
    onClickStopAndPlayCancelBrand() {
      this.selectPlayBranditem = null;
      this.isStopAndPlayModalBrand = false;
    },
    endedMySound() {
      this.activeMySoundIndex = -1;
    },
    onClickMySoundAdd() {
      if (this.mysoundmusicList.length >= 10) {
        this.alertText = `파일은 최대 10개까지만 등록 가능합니다.`;
        this.isAlert = true;
        return;
      }

      this.isMySoundMenu = !this.isMySoundMenu;
    },
    onMySoundOutSideClick($event) {
      this.isMySoundMenu = false;
    },
    onClickVoiceRecording() {
      this.isVoiceRecording = !this.isVoiceRecording;
      if (!this.isVoiceRecording) {
        this.$store.dispatch('player/settingMySoundList', {
          userId: this.$cookies.get('userId'),
          spaceId: this.$cookies.get('spaceId')
        });
      }
    },
    onTextModal() {
      this.isTextUpload = false;
      if (!this.isTextUpload) {
        this.$store.dispatch('player/settingMySoundList', {
          userId: this.$cookies.get('userId'),
          spaceId: this.$cookies.get('spaceId')
        });
      }
    },
    onFileModal() {
      this.isFileUpload = false;
      if (!this.isFileUpload) {
        this.$store.dispatch('player/settingMySoundList', {
          userId: this.$cookies.get('userId'),
          spaceId: this.$cookies.get('spaceId')
        });
      }
    },
    fileModalOpen() {
      this.isFileUpload = true;
    },
    textModalOpen() {
      this.isTextUpload = true;
    }
  },
  watch: {
    activeTabIndex(val) {
      if (val === 0) {
        this.activeIndex = -1;
        document.querySelectorAll('video[id*="bVideo"]').forEach(item => item.pause());
        this.getMySoundEle().pause();
      } else if (val === 1) {
        this.activeMySoundIndex = -1;
        if (this.getBrandEle()) this.getBrandEle().pause();
      }
    }
  }
});
</script>
<style scoped src="@/assets/css/modal/player/brandmusic.css"></style>
